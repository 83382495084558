import React from "react";
import { useFormContext } from "react-hook-form";
import { Category, Product } from "@models";
import { escapeCategoryId } from "../util";
import BudgetCategoryAmounts from "./BudgetCategoryAmounts";
import CategoriesSelection from "./CategoriesSelection";
import { ConfigBudgetMaster } from "./types";

type Props = {
	category: Category;
	product: Product;
};

export default function BudgetCategoryDetails(props: Props) {
	const { product, category } = props;

	const { watch } = useFormContext<ConfigBudgetMaster>();

	const namePrefix = `amounts.${escapeCategoryId(category.id)}` as const;
	const isActive: boolean = watch(`${namePrefix}.enabled`);
	const productsWithCategories = [
		"spend",
		"mobility",
		"sachbezug",
		"fitAndRelax",
		"homeAndWork",
	];
	const hasCategories = productsWithCategories.includes(product.id);

	return (
		<div data-testid={`budgetCategorySection-${category.id}`}>
			{/* rather than conditionally mount this element, set it to hidden when inactive to avoid losing the edit values */}
			<div style={isActive ? undefined : { display: "none" }}>
				<BudgetCategoryAmounts productId={product.id} category={category} />
				{hasCategories && (
					<CategoriesSelection
						productId={product.id}
						budgetCategory={category}
					/>
				)}
			</div>
		</div>
	);
}
