import { isAfter, isBefore, isEqual, startOfDay } from "date-fns";
import BudgetMaster from "../../../../models/BudgetMaster";
import {
	BudgetMasterAmounts,
	Category,
	NewBudgetMaster,
	RootProductId,
	UpdatedBudgetMaster,
	UserWithBalanceAccounts,
} from "@models";
import { getDateFlags } from "@util";
import { formatDateMonth } from "@service/util";
import { formatDate } from "@components/forms/datePickerUtils";

export function getDefaultBudgetAmounts(
	category: Category
): BudgetMasterAmounts {
	// TODO: get default values from somewhere
	return {
		category: category.id,
		maxAmountDay: null,
		maxAmountMonth: category.maxAmountMonth ?? 2000,
	};
}

export function escapeCategoryId(categoryId: string): string {
	// convert "."s to "_"s in category ids in order to not confuse react-hook-form accessors
	if (categoryId.includes("_"))
		throw new Error(
			`Cannot escape category id ${categoryId}: it already contains an underscore!`
		);
	return categoryId.replace(/\./g, "_");
}

export function unescapeCategoryId(escapedCategoryId: string): string {
	return escapedCategoryId.replace(/_/g, ".");
}

export function splitBudgetMasters(budgetMasters: BudgetMaster[]) {
	const activeBudgetMasters: BudgetMaster[] = [];
	const expiredBudgetMasters: BudgetMaster[] = [];
	const futureBudgetMasters: BudgetMaster[] = [];

	budgetMasters.forEach((budgetMaster) => {
		const { isActive, isExpired, isInFuture } = getDateFlags(
			budgetMaster.availableFrom,
			budgetMaster.availableUntil
		);
		if (isActive) {
			activeBudgetMasters.push(budgetMaster);
		} else if (isExpired) {
			expiredBudgetMasters.push(budgetMaster);
		} else if (isInFuture) {
			futureBudgetMasters.push(budgetMaster);
		}
	});
	return { activeBudgetMasters, expiredBudgetMasters, futureBudgetMasters };
}

export function isNewBudgetMaster(
	b: NewBudgetMaster | UpdatedBudgetMaster
): b is NewBudgetMaster {
	return !("budgetMasterId" in b);
}

export function isDateRangeWithin(
	from: Date | null,
	until: Date | null,
	budgetMaster: NewBudgetMaster | UpdatedBudgetMaster
) {
	const { availableFrom, availableUntil } = budgetMaster;

	if (!availableFrom) {
		if (!from) return true;
		if (!availableUntil) return true;
		return (
			isBefore(startOfDay(from), availableUntil) ||
			isEqual(startOfDay(from), availableUntil)
		);
	}

	if (!availableUntil) {
		if (!until) return true;

		return isAfter(startOfDay(until), availableFrom);
	}

	if (until && isBefore(startOfDay(until), availableFrom)) return false;

	if (from && isAfter(startOfDay(from), availableUntil)) return false;

	return true;
}

export function canConfigureBalanceAccount(rootProductId: RootProductId) {
	return (
		rootProductId === "spend" ||
		rootProductId === "web" ||
		rootProductId === "lunch"
	);
}

export function getBalanceAccount(
	user: UserWithBalanceAccounts,
	budgetMaster: BudgetMaster | NewBudgetMaster
) {
	return user.balanceAccounts.find(
		(ba) => ba.budgetCategory === budgetMaster.category
	);
}

const YEARLY_BUDGETS = ["fitAndRelax", "homeAndWork"];

export function isYearlyBudget(categoryId: string | undefined | null): boolean {
	return !!categoryId && YEARLY_BUDGETS.some((c) => categoryId.startsWith(c));
}

export const collisionErrorMessage = {
	default:
		"Im angegebenen Zeitraum liegen bereits andere Budgets. Ändern Sie den Verfügbarkeitszeitraum oder passen Sie die anderen Budgets an.",
	monthCollision: (availableFrom: Date) =>
		`Im Monat ${formatDateMonth(
			availableFrom
		)} liegt bereits ein anderes Budget. Es dürfen keine zwei Budgets in demselben Monat liegen.`,
	monthCollisionChangedEndDate: (availableFrom: Date, endDate: Date) =>
		`In dem angegebenen Zeitraum liegt ein anderes Budget. Wenn Sie ein neues Budget erzeugen, wird das bestehende am ${formatDate(
			endDate
		)} beendet und ab dem ${formatDate(availableFrom)} ein neues erzeugt.`,
	annualCollision:
		"Es darf pro Jahr nur max. eine Produkteinstellung geben. Überprüfen Sie andere Budgets dieses Produkts und legen Sie andere Gültigkeitsbereiche fest",
};

export function filterCategoriesByAmount(
	categories: Category[],
	amount: number
) {
	return categories.filter((category) => category.maxAmountMonth === amount);
}
